import React, { useState } from "react";
import Search from "./Components/Search";
import { api } from "./Utils/api";
import { Timeline, Tweet } from "react-twitter-widgets";
import Header from "./Components/Header";
import eyes from "./Assets/eyes.png";

function App() {
  const [apiData, setApiData] = useState(null);
  const [loading, setLoading] = useState(false);
  const handler = async (value) => {
    if (!value) return;
    console.log(value);

    setLoading(true);
    api(`search/${value}`)
      .then((data) => setApiData(data))
      .then(() => setLoading(false));
  };
  return (
    <>
      <Header />
      <div>
        <div className="text-6xl flex flex-col items-center mt-32 leading-18 tracking-tighter">
          <p>WGMInterfaces</p>
          <div className="flex items-center space-x-6">
            <p>Pre-mint</p>
            <img src={eyes} className="h-10 w-23" />
            <p>checker</p>
          </div>
        </div>
        <p className="text-2xl text-gray-2 mt-4 tracking-tight">
          Data sourced from the{" "}
          <a
            href="https://docs.google.com/spreadsheets/d/11OTeOnK-QuCrvYfEiNXQ3CGOz3YOIvCzNbpomVZIi5E/htmlview"
            target="_blank"
            rel="noreferrer"
            className="underline"
          >
            original pre-mint list
          </a>{" "}
          by WGMInterfaces
        </p>
      </div>
      <Search />
    </>
  );
}

export default App;

import React from "react";
import { ReactComponent as Logo } from "../Assets/logo.svg";
import { ReactComponent as Twitter } from "../Assets/Twitter.svg";

const Header = () => {
  return (
    <div className="w-full  mt-7 px-8">
      <div className="max-w-screen-xl mx-auto flex justify-between items-center">
        <div className="flex items-center space-x-7 text-gray-2">
          <Logo />
          <a
            href="https://twitter.com/ironpaw_nft"
            target="_blank"
            rel="noreferrer"
            className="font-medium hover:underline"
          >
            Made by @ironpaw_nft
          </a>
        </div>
        <div className="flex items-center space-x-16">
          <a
            href="#"
            className="flex items-center font-medium text-xl text-gray-3 rounded-lg hover:opacity-75"
          >
            Duplicate Checker
          </a>
          <a
            href="https://twitter.com/WGMInterfaces"
            target="_blank"
            rel="noreferrer"
            className="flex items-center font-medium text-xl text-white px-6 py-4 bg-gray-3 rounded-xl hover:bg-opacity-90"
          >
            @WGMInterfaces
            <Twitter className="ml-3" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Header;
